<template>
  <b-card class="payment--method-wrapper">
    <div
      class="payment--method"
      v-if="checkoutInfo && checkoutInfo.payment_methods && checkoutInfo.payment_methods.length > 0"
    >
      <div class="top--part">
        <span>{{ $t('booking.headerTitle') }}</span>
      </div>
      <div class="middle--part">
        <div class="payment--method-group">
          <div class="group--title">
            <span>{{ $t('booking.virtualAccount') }}</span>
            <span class="required">*</span>
          </div>
          <div class="group--content">
            <card-payment-method
              :payment-method="'permata_va'"
              v-if="checkoutInfo.payment_methods.includes('permata_va')"
            ></card-payment-method>
            <card-payment-method
              :payment-method="'bni_va'"
              v-if="checkoutInfo.payment_methods.includes('bni_va')"
            ></card-payment-method>
            <card-payment-method
              :payment-method="'bri_va'"
              v-if="checkoutInfo.payment_methods.includes('bri_va')"
            ></card-payment-method>
          </div>
        </div>
        <div class="payment--method-group" v-if="checkoutInfo.payment_methods.includes('gopay')">
          <div class="group--title">
            <span>{{ $t('booking.wallet') }}</span>
          </div>
          <div class="group--content">
            <card-payment-method
              :payment-method="'gopay'"
              v-if="checkoutInfo.payment_methods.includes('gopay')"
            ></card-payment-method>
          </div>
        </div>
        <div
          class="payment--method-group"
          v-if="checkoutInfo.payment_methods.includes('credit_card')"
        >
          <div class="group--title">
            <span>{{ $t('booking.creditCard') }}</span>
          </div>
          <div class="group--content">
            <card-payment-method
              :payment-method="'credit_card'"
              v-if="checkoutInfo.payment_methods.includes('credit_card')"
            ></card-payment-method>
          </div>
        </div>
      </div>
      <div class="checkout--terms mt-4">
        <div class="text-muted">{{ $t('booking.message1') }}</div>
      </div>
    </div>
  </b-card>
</template>

<script>
import CardPaymentMethod from './partials/card-payment-method';
export default {
  name: 'payment-method',
  components: { CardPaymentMethod },
  computed: {
    checkoutInfo() {
      return this.$store.state.checkout.checkoutInfo;
    },
  },
  methods: {
    async payNow() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.payment--logo {
  width: 40px;
  margin-left: 8px;
}
</style>
