<template>
  <section class="checkout--action">
    <div v-if="checkoutInfo">
      <div class="top--part">
        <div class="checkout--title">{{ $t('checkout.title') }}</div>
        <div class="checkout--subtitle">{{ $date.format(new Date(), 'dd MMM yyyy') }}</div>
      </div>
      <div v-if="checkoutInfo.items && checkoutInfo.items.length > 0">
        <div
          class="content--row"
          v-for="(product, index) in checkoutInfo.items"
          :key="`checkout-product-${index}`"
        >
          <div>{{ product.name }}</div>
          <div v-if="product.discount_sum && product.discount_sum > 0">
            {{ $n(product.price_sum - product.discount_sum, 'currency', 'id-ID') }}
          </div>
          <div v-else>
            {{ $n(product.price_sum, 'currency', 'id-ID') }}
          </div>
        </div>
      </div>
      <div class="subtotal--row">
        <div>{{ $t('general.subPrice') }}</div>
        <div>
          {{ $n(checkoutInfo.sub_total_price, 'currency', 'id-ID') }}
        </div>
      </div>
      <div class="content--row" v-if="checkoutInfo.vat">
        <div>{{ $t('checkout.vat') }}</div>
        <div>
          {{ $n(checkoutInfo.vat, 'currency', 'id-ID') }}
        </div>
      </div>
      <div class="content--row" v-if="valid">
        <div class="promo" style="padding-right: 8px">
          {{ `${$t('checkout.promo')}: ${promoCode}` }}
        </div>
        <div class="promo" style="white-space: nowrap">
          {{ `- ${$n(totalDiscount, 'currency', 'id-ID')}` }}
        </div>
      </div>
      <div class="total--row">
        <div>{{ $t('general.totalPrice') }}</div>
        <div>{{ $n(checkoutInfo.total_net_price - totalDiscount, 'currency', 'id-ID') }}</div>
      </div>
    </div>
    <button v-if="checkoutInfo" class="btn btn-primary" @click="$emit('submit')">
      {{ checkoutInfo.total_net_price > 0 ? $t('checkout.pay') : $t('checkout.submit') }}
    </button>
    <!--    <b-card class="request&#45;&#45;book-info mt-2" v-if="bookingInfo">-->
    <!--      <ul v-if="product.type === 'R'">-->
    <!--        <li>{{ $t('booking.tos.message1') }}</li>-->
    <!--      </ul>-->
    <!--      <ul v-else-if="product.type === 'S' && !freeReservationFee">-->
    <!--        <li>{{ $t('booking.tos_jual.message1') }}</li>-->
    <!--        <li>{{ $t('booking.tos_jual.message2') }}</li>-->
    <!--        <li>{{ $t('booking.tos_jual.message3') }}</li>-->
    <!--        <li>{{ $t('booking.tos_jual.message4') }}</li>-->
    <!--      </ul>-->
    <!--      <ul v-else-if="product.type === 'S' && freeReservationFee">-->
    <!--        <li>{{ $t('booking.tos_jual_free.message1') }}</li>-->
    <!--        <li>{{ $t('booking.tos_jual_free.message2') }}</li>-->
    <!--        <li>{{ $t('booking.tos_jual_free.message3') }}</li>-->
    <!--        <li>{{ $t('booking.tos_jual_free.message4') }}</li>-->
    <!--      </ul>-->
    <!--    </b-card>-->
  </section>
</template>

<script>
// const PromoIndicator = () => import('@/components/listings/partials/promo-indicator');
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  name: 'checkout-info',
  mixins: [HelperMixin],
  // components: { PromoIndicator },
  computed: {
    ...mapState({
      checkoutInfo: (state) => state.checkout.checkoutInfo,
      valid: (state) => state.checkout.promo.valid,
      promoCode: (state) => state.checkout.promo.promoCode,
      discountAmount: (state) => state.checkout.promo.discountAmount,
      discountPercentage: (state) => state.checkout.promo.discountPercentage,
    }),
    totalDiscount() {
      if (this.valid) {
        if (this.discountAmount) {
          return this.discountAmount;
        } else if (this.discountPercentage) {
          return Math.ceil((this.checkoutInfo.sub_total_price * this.discountPercentage) / 100);
        }
      }
      return 0;
    },
  },
};
</script>

<style scoped></style>
